import {  API_URL_EVENTOS } from './../../app.constants';
import { Evento } from './../../eventos/evento.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class EventoDataService {

  constructor(private http: HttpClient) { }



  apiEventos=`${API_URL_EVENTOS}/eventos`
  apiEventos2=`${API_URL_EVENTOS}`

  getAllEventosVisibles(){
    return this.http.get<Evento[]>(`${this.apiEventos}/estado`);
  }
  getEventoIdPerfil(pIdEvento:string){
    return this.http.get(`${this.apiEventos}/${pIdEvento}/estado`);
  }

  getEventoIdPerfilNoSoldOut(pIdEvento:string){
    return this.http.get(`${this.apiEventos}/${pIdEvento}/localidad`);
  }

  getEventoPorLocalidadId(pIdLocalidad:number){
    return this.http.get(`${this.apiEventos2}/localidades/localidad/${pIdLocalidad}/evento`);
  }

  getEventoIdPerfilPulep(pIdEvento:string){
    return this.http.get(`${this.apiEventos}/pulep/${pIdEvento}`);
  }

  getAllEventosFiltro( pIdCiudad: number, pTexto: string, pTipo: string) {
    const url = `${this.apiEventos}/filtro/${pIdCiudad}/${pTexto}/${pTipo}`;
    return this.http.get<any>(url);
  }

  getEventoId(pIdEvento){
    return this.http.get<any>(`${this.apiEventos}/${pIdEvento}/perfil`);
  }



}
